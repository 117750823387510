import React from 'react'
import * as styles from './BreadcrumbList.module.scss'

const BreadcrumbList = ({ children }) => (
  <div className="wrapper">
    <ol
      itemScope
      itemType="https://schema.org/BreadcrumbList"
      className={styles.BreadcrumbList}
    >
      {children}
    </ol>
  </div>
)

export default BreadcrumbList
