import React from 'react'

const BreadcrumbItem = ({ children, position }) => (
  <li
    itemProp="itemListElement"
    itemScope
    itemType="https://schema.org/ListItem"
  >
    {children}
    <meta itemProp="position" content={position} />
  </li>
)

export default BreadcrumbItem
